/* general rules */
:root {
  --main-color: #f0950f;
  --page-background-color: #242424;
  --highlight-over: #424242;
}

body {
  background-color: var(--page-background-color);
  text-align: center;
  font-family: 'League Spartan', sans-serif;
  color: white;
}

h4 {
  margin-top: 8px;
}

button {
  border: none;
}

button:hover {
  border: var(--main-color);
}

button:focus {
  outline: var(--main-color) !important;
  border: var(--main-color);
}

input:focus {
  outline: none !important;
  border-color: var(--main-color);
  box-shadow: 0 0 5px var(--main-color);
}

/* top elements */
.top-button-image {
  width: 15px;
}

.top-button {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid var(--main-color);
  background-color: var(--page-background-color);
}

.top-button:hover {
  border: 2px solid var(--main-color);
  background-color: var(--highlight-over);
}

.right-top-div {
  display: inline-block;
}

.clue-list {
  padding: 0px 10px;
  border: 2px solid var(--main-color);
}

.game-title {
  font-family: 'Monoton', cursive;
  color: var(--main-color);
  font-size: 45px;
  display: flex;
  margin: 0px auto;
}

.maintenanceMode {
  background-color: transparent !important;
}

.spacer {
  height: 30px;
}

.btn-space {
  width: 4px;
  height: auto;
  display: inline-block;
}

.header {
  overflow: hidden;
  position: relative;
  margin: 0px auto;
}

.left {
  text-align: center;
}

.right {
  top: 0px;
  right: 0px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border-radius: 4px;
}

.scott-logo {
  width: 25px;
}

/* autocomplete elements */
input {
  border: 2px solid var(--main-color);
  padding: 0.5rem;
  width: 400px;
  background-color: var(--page-background-color);
  border-radius: 10px;
  color: white;
  height: 55px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.autocompleteWrapper {
  width: 400px;
  margin: 0px auto;
}

.suggestionsWrapper {
  width: 400px;
  margin: 0px auto;
}

.suggestions {
  z-index: 100;
  color: white;
  background-color: var(--page-background-color);
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 400px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: var(--highlight-over);
  color: white;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.searchBar {
  position: relative;
}

.lossSpace {
  height: 30px;
}

.text-area {
  padding-bottom: 30px;
}

.text-label {
  display: block;
}

.lastRowTicket {
  height: 20px;
  padding-top: 30px;
}

.flip-button {
  background-color: inherit;
  border: none;
}

.flip-image {
  width: 30px;
}

.share-button {
  width: 90px;
  height: 55px;
  color: var(--main-color);
  background-color: inherit;
  border: 2px solid var(--main-color);
  border-radius: 10px;
}

.share-button:hover {
  background-color: var(--main-color);
  color: black;
}

.pixel-movie-image {
  width: 700px;
  height: 393;
}

.table-ticket {
  width: 700px;
  background-color: var(--highlight-over);
  border: 2px solid var(--main-color);
  align-items: center;
  margin: auto;
}

.anon-hint {
  color: #999;
}

.ticket-ends {
  width: 8%;
  padding: 20px 0px;
}

.backside-of-ticket {
  float: left;
  width: 50%;
}

.date {
  transform: rotate(-180deg);
  font-size: 25px;
}

.everythingelse {
  width: 600px;
  height: 337px;
}

.posterImg {
  filter: blur(8px);
}

.middle-hint-container {
  display: contents;
}

.middle-hints {
  display: inline-block;
  width: 50%;
}

.guess-box {
  height: 40px;
}

.btn-divider {
  width: 10px;
  display: inline-block;
}

.movie-title-section {
  display: flex;
  justify-content: space-between;
  width: 700px;
  margin: 0 auto;
  padding: 20px 0px;
}

.hint-title-section {
  margin: auto 0px;
}

.hint-lines {
  height: 2px;
  margin: auto 0px;
  width: 40%;
  background: var(--main-color);
  right: 0;
}

.hint-line-divider {
  width: 20px;
}

.hint-board {
  background-color: white;
}

.hints-overall {
  align-items: center;
  margin: auto;
  width: 80%;
}

.guess-board {
  margin: 0px auto;
  width: 700px;
  padding: 20px;
  background-color: black;
}

.guess-heading {
  color: var(--main-color);
}

.name-of-guess {
  color: black;
  margin: 0px;
}

.guess-list {
  font-family: 'Rokkitt', serif;
  margin: 0px auto;
  width: 100%;
  background-color: white;
  padding: 20px;
}

.guess-section {
  border: 2px solid black;
}

.footer {
  padding-bottom: 70px;
}

/* smaller screens */
@media screen and (max-width: 1000px) {
  body {
    font-size: 1.4rem;
  }

  .middle-btn-space {
    display: none;
  }

  .game-title h1 {
    font-size: 120px;
  }

  .right {
    display: grid;
  }

  .top-button {
    height: 50px;
    width: 50px;
  }

  .guess-box {
    height: 100px;
    width: 90%;
    font-size: 40px;
  }

  .guess-board {
    width: 100%;
  }

  .name-of-guess {
    font-size: 3rem;
  }

  .btn-divider {
  }

  .text-label {
    font-size: 40px;
  }

  .ticket {
    width: 90%;
    height: 500px;
    margin: auto;
  }

  .lastRowTicket {
    padding-top: 60px;
  }

  .flip-image {
    width: 50px;
  }

  .hint-board {
    /* width: 90%; */
    background-color: red;
  }

  .hints-overall {
    align-items: center;
    width: 100%;
  }

  .share-button {
    width: 100px;
    height: 60px;
  }

  .guess-row {
    background-color: aquamarine;
  }
}

/* phone screens */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  h4 {
    font-size: 2.7rem;
  }

  .game-title {
    font-size: 5rem;
  }

  .top-button {
    height: 80px;
    width: 80px;
  }

  .top-button-image {
    width: 35px;
  }

  .scott-logo {
    width: 40px !important;
  }

  input {
    width: 85%;
    height: 100px;
    font-size: 2.5rem;
  }

  .guess-board {
    width: 90%;
    /* padding: 0px; */
  }

  .movie-title-section {
    width: 90%;
  }

  .hint-title-section {
    font-size: 3rem;
  }

  .pixel-movie-image {
    width: 90%;
  }

  .table-ticket {
    width: 90%;
  }

  .suggestions {
    width: 85%;
    font-size: 2.5rem;
    max-height: 500px;
  }

  .suggestionsWrapper {
    width: 85%;
    margin: unset;
  }

  .autocompleteWrapper {
    width: 85%;
  }
}
